import * as React from 'react'
import * as css from './search.module.css'
import DefaultLayout from 'layouts/Default'
import { BlogProvider, BlogContext } from 'components/blog/BlogStore'
import Search from 'components/blog/Search'
import ArticlePreview from 'components/blog/ArticlePreview'

const searchBody = (body, query) => body.some(({ children }) =>
  children.some(({ text }) =>
    !!query && text.toLowerCase().includes(query.toLowerCase())
  )
)

const Results = ({ query }) => {
  const { allBlogs } = React.useContext(BlogContext)

  const results = allBlogs.filter(({ title, body }) => (
    !!query && (title.toLowerCase().includes(query.toLowerCase()) || searchBody(body, query))
  ))

  return <>
    <h2 className={css.stats}>Found {results.length} result(s)</h2>

    {!!results.length && <ul className={css.list}>
      {results.map((post, key) => (
        <ArticlePreview post={post} query={query} key={key} />
      ))}
    </ul>}
  </>
}

const BlogSearchPg = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const query = params.get`query`

  return (
    <DefaultLayout className="ctnr padding">
      <BlogProvider>
        <h1 className="h1">Search for "<i>{query}</i>"</h1>

        <div className={css.search}>
          <Search className={css.searchbox} />
        </div>

        <Results query={query} />
      </BlogProvider>
    </DefaultLayout>
  )
}

export default BlogSearchPg
