import * as React from 'react'
import * as css from './ArticlePreview.module.css'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import highlight from 'utils/highlight'
import CategoryAndDate from './CategoryAndDate'
import CTALink from 'components/common/CTALink'
import clsx from 'clsx'

const ArticlePreview = ({ post, query, large }) => (
  <article className="anim-fadein">
    <Link className={`${css.link} no-underline`} to={`/blog/${post.seo.slug.current}`}>
      {post.thumbnail
        ? (
          <GatsbyImage
            className={css.img}
            image={getImage(post.thumbnail.asset)}
            alt={post.title}
          />
        ) : (
          <div className={css.placeholder} />
        )
      }

      <div
        className={clsx(large ? 'h1': 'h3', 'transition-colors')}
        to={`/blog/${post.seo.slug.current}`}
        dangerouslySetInnerHTML={{ __html: highlight(post.title, query) }}
      />

      <CategoryAndDate {...post} />

      {large && <>
        <p>{post.seo.description}</p>

        <CTALink className="link-strong" url={`/blog/${post.seo.slug.current}`}>
          Read Article
        </CTALink>
      </>}
    </Link>
  </article>
)

export default ArticlePreview
