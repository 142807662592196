function highlight(target, query) {
	if (!query) return target

	const start = target.toLowerCase().indexOf(query.toLowerCase())
	const end = start + query.length

	if (start < 0) return target

	const split = target.split('')

	split.splice(end, 0, '</mark>')
	split.splice(start, 0, '<mark>')

	return split.join('')
}

export default highlight
